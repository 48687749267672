import { LocaleWithLangName } from "~/classes/LocaleWithLangName";
import { Language } from "@ambii-com/core";

// all locales currently supported across app
export const allLanguages: LocaleWithLangName[] = [
  { locale: Language.JAPANESE, name: "日本語" },
  { locale: Language.ENGLISH, name: "English" },
  { locale: Language.CHINESE_SIMPLIFIED, name: "中文 (简体)" },
  {locale: Language.CHINESE_TRADITIONAL, name: "中文 (繁體)" },
  { locale: Language.KOREAN, name: "한국어" },
  { locale: Language.VIETNAMESE, name: "Tiếng Việt" },
  { locale: Language.PORTUGESE, name: "Português" },
  { locale: Language.SPANISH, name: "Español" },
  { locale: Language.FRENCH, name: "Français" },
  { locale: Language.THAI, name: "แบบไทย" },
  { locale: Language.NEPALESE, name: "नेपाली" },
  { locale: Language.TAGALOG, name: "Tagalog" },
];
