import { QuestionAnswerPair, FormQuestion, Appointment, AppointmentSchedule } from "@ambii-com/core";
import { FormSession, AppointmentData } from "@/classes/FormSession";
import { AppointmentFetchError } from "@/classes/Error"
import { fsLocations } from "@/dao/FsLocations";
import { Form, DocumentError, AppointmentStatus } from "@ambii-com/core";
import { GetterTree, ActionTree, MutationTree } from "vuex";
import { FormPage, FormPageIterator } from "@ambii-com/core";
import ls from "localstorage-slim";

export const state = () => ({
  formDoc: null as Form | null,
  formSession: null as FormSession | null,
  pageIterator: null as FormPageIterator | null,
});

export type FormState = ReturnType<typeof state>;

export const getters: GetterTree<FormState, any> = {
  getAppointmentId(state): string|undefined {
    return state.formSession?.appointmentData?.appointment?.id
  },
  allPagesWereAccessed(state) {
    return (
      state.pageIterator.currentPageIndex >= state.pageIterator.formPages.length
    );
  },
  storeIsNotFullyInitialized(state) {
    return (
      state.formSession === null ||
      state.pageIterator === null ||
      state.formDoc === null
    );
  },
  // Returns QuestionAnswerPairs before current page in pageIterator
  previousPairs(state): QuestionAnswerPair[] {
    if (!state.pageIterator) {
      throw new Error("Stored pageIterator is empty or was reset");
    } else if (!state.formSession) {
      throw new Error("Stored formSession is empty or was reset");
    }
    const pageId = state.pageIterator.getCurrentPage?.id;
    if (!pageId && state.pageIterator.currentPageIndex <= -1) {
      return [];
    } else if (
      !pageId &&
      state.pageIterator.currentPageIndex >= state.pageIterator.formPages.length
    ) {
      return state.formSession.retrieveAllPairs;
    } else if (!pageId) {
      throw new Error(
        "getCurrentPage returned undefined but currentPageIndex did not exceed bounds"
      );
    } else {
      return state.formSession.retrievePairsBeforePage(pageId);
    }
  },
  fromAppointment: (state) => {
    return !!state.formSession?.appointmentData;
  },
};

export const mutations: MutationTree<FormState> = {
  initializeFormSession(state, formDoc: Form) {
    state.formSession = FormSession.initializeFormSessionFromPageIds(
      formDoc.pageIds
    );
  },
  setBaseForm(state, formDoc: Form) {
    state.formDoc = formDoc;
  },
  setAppointmentData(state, appointmentData: AppointmentData ) {
    state.formSession.appointmentData = appointmentData
  },
  buildPageIterator(state, formPages: FormPage[]) {
    state.pageIterator = new FormPageIterator(formPages);
  },
  buildQuestionAnswerPairs(
    state,
    { pageId, formQuestions, URLQuery }: { pageId: PageId; formQuestions: FormQuestion[], URLQuery: Record<string, string | string[]> }
  ) {
    if (!state.formSession) {
      throw new Error("Stored formSession is empty or was reset");
    }
    // initialize with appointment form answers if exist
    state.formSession.buildQuestionAnswerPairs(pageId, formQuestions, state.formSession.appointmentData, URLQuery);
  },
  updateQuestionAnswerPairs(
    state,
    { pageId, pairs }: { pageId: PageId; pairs: QuestionAnswerPair[] }
  ) {
    if (!state.formSession) {
      throw new Error("Stored formSession is empty or was reset");
    }
    state.formSession.updateQuestionAnswerPairs(pageId, pairs);
  },
  editQuestionAnswerPair(
    state,
    { pageId, newPair }: { pageId: PageId; newPair: QuestionAnswerPair }
  ) {
    if (!state.formSession) {
      throw new Error("Stored formSession is empty or was reset");
    }
    // Required for reactivity
    state.formSession = state.formSession.editQuestionAnswerPair(
      pageId,
      newPair
    );
  },
  goForwardPage(state, pairsBeforeAndOnCurrent: QuestionAnswerPair[]) {
    if (!state.pageIterator) {
      throw new Error("Stored pageIterator is empty or was reset");
    }
    state.pageIterator.setNextValidIndex(pairsBeforeAndOnCurrent);
  },
  goBackPage(state, previousPairs: QuestionAnswerPair[]) {
    if (!state.pageIterator) {
      throw new Error("Stored pageIterator is empty or was reset");
    }
    state.pageIterator.setPreviousValidIndex(previousPairs);
  },
  jumpToPage(
    state,
    {
      pageId,
      previousPairs,
    }: { pageId: PageId; previousPairs: QuestionAnswerPair[] }
  ) {
    if (!state.pageIterator) {
      throw new Error("Stored pageIterator is empty or was reset");
    }
    state.pageIterator.setCurrentPage(pageId, previousPairs);
  },
  backupFormSession(state) {
    if (!state.formSession) {
      throw new Error("Stored formSession is empty or was reset");
    } else if (!state.formDoc) {
      throw new Error("Stored formDoc document is empty or was reset");
    }
    try {
      const strippedSession = state.formSession.stripUnbackupableContents()
      ls.set(
        `${state.formDoc.id}:sessionBackup`,
        strippedSession.convertToObject(),
        { ttl: 300, encrypt: true }
      );
    } catch (e) {
      console.error("localStorage may be full/not enabled: " + e);
    }
  },
  rehydrateBackupFormSession(state, form: Form) {
    if (state.formSession) {
      throw new Error("Existing formSession found in vuex store");
    }
    const sessionBackup: Object | null = ls.get(`${form.id}:sessionBackup`, {
      decrypt: true,
    });
    if (!sessionBackup) {
      // TODO use error class
      throw new Error("backup-not-found");
    } else if (
      sessionBackup.hasOwnProperty("createdAt") &&
      form.lastFormUpdateAt &&
      sessionBackup["createdAt"] < form.lastFormUpdateAt
    ) {
      throw new Error("backup-expired");
    }

    state.formSession = sessionBackup
      ? FormSession.createFromPlain(sessionBackup)
      : null;
    ls.remove(`${form.id}:sessionBackup`);
  },
  resetFormState(state) {
    state.formDoc = null;
    state.formSession = null;
    state.pageIterator = null;
  },

};

export const actions: ActionTree<FormState, any> = {
  async initializeFormStore({ state, dispatch, commit }, formId: FormId) {
    await dispatch("fetchBaseForm", formId);
    // Skip initialize if loaded in through backup
    if (!state.formSession) {
      commit("initializeFormSession", state.formDoc);
    }
    await dispatch("fetchFormPages", state.formDoc.pageIds);
  },
  async fetchAppointmentData({commit}, appointmentId) {
    let appointment: Appointment;
    try {
       appointment = await fsLocations.getAppointmentRef(appointmentId)
      .get()
      .then(Appointment.fromDoc)
    } catch (e) {
      throw new AppointmentFetchError('appointment-not-found', appointmentId)
    }
    if(appointment.status === AppointmentStatus.CANCELED) {
      throw new AppointmentFetchError('appointment-canceled', appointment)
    }
    const schedule = await fsLocations.getScheduleRef(appointment.bucketId)
      .get()
      .then(AppointmentSchedule.fromDoc)
    commit("setAppointmentData", {schedule: schedule, appointment: appointment} )
  },
  async fetchBaseForm({ commit }, formId: FormId) {
    const formDoc: Form = await fsLocations
      .getFormRef(formId)
      .get()
      .then(Form.fromDoc)
      .catch((error) => {
        if (error instanceof DocumentError) throw error;
        else throw new Error(`Failed to fetch formDoc: ${error}`);
      });
    commit("setBaseForm", formDoc);
  },
  async fetchFormPages({ commit }, pageIds: PageId[]) {
    const formPages: FormPage[] = await Promise.all(
      fsLocations.getFormPageRefsFromFormPageIds(pageIds).map(async (query) => {
        return await query.get().then(({ docs }) => FormPage.fromDocs(docs));
      })
    )
      .then((pages: FormPage[][]) => pages.flat())
      .catch((error) => {
        if (error instanceof DocumentError) throw error;
        else throw new Error(`Failed to fetch pages: ${error}`);
      });
    commit("buildPageIterator", formPages);
  },
  async fetchAndBuildQuestions(
    { commit, state },
    { pageId, questionIds, URLQuery }: { pageId: PageId; questionIds: QuestionId[], URLQuery: Record<string, string | string[]> }
  ): Promise<QuestionAnswerPair[]> {
    // silence document errors for FormQuestions since they are lazily fetched
    const formQuestions: Array<FormQuestion | DocumentError> =
      await Promise.all(
        questionIds.map(async (questionId) => {
          try {
            return await fsLocations
              .getFormQuestion(questionId)
              .get()
              .then(FormQuestion.fromDoc);
          } catch (e) {
            if (e instanceof DocumentError) {
              return e;
            } else throw e;
          }
        })
      ).then((questions) => {
        return questions.sort((a,b) => a instanceof FormQuestion && b instanceof FormQuestion ? (a.order > b.order ? 1 : -1): 0)
      }).catch((error) => {
        throw new Error(`Failed to fetch questions: ${error}`);
      });
    commit("buildQuestionAnswerPairs", {
      pageId: pageId,
      formQuestions: formQuestions,
      URLQuery: URLQuery
    });
    return state.formSession.pageToPairsMap.get(pageId);
  },
  updateQuestionAnswerPairs(
    { commit },
    { pageId, pairs }: { pageId: PageId; pairs: QuestionAnswerPair[] }
  ) {
    commit("updateQuestionAnswerPairs", { pageId: pageId, pairs: pairs });
  },
  editQuestionAnswerPair(
    { commit },
    { pageId, newPair }: { pageId: PageId; newPair: QuestionAnswerPair }
  ) {
    commit("editQuestionAnswerPair", { pageId: pageId, newPair: newPair });
  },
  goForwardPage(
    { state, commit, getters },
    currentPairs: QuestionAnswerPair[]
  ): PageId | undefined {
    commit("goForwardPage", getters.previousPairs.concat(currentPairs));
    if (!state.pageIterator) {
      throw new Error("Stored pageIterator is empty or was reset");
    }
    return state.pageIterator.getCurrentPage?.id;
  },
  goBackPage({ state, commit, getters }): PageId | undefined {
    commit("goBackPage", getters.previousPairs);
    if (!state.pageIterator) {
      throw new Error("Stored pageIterator is empty or was reset");
    }
    return state.pageIterator.getCurrentPage?.id;
  },
  jumpToPage({ state, commit, getters }, pageId: PageId): FormPage | undefined {
    commit("jumpToPage", {
      pageId: pageId,
      previousPairs: getters.previousPairs,
    });
    if (!state.pageIterator) {
      throw new Error("Stored pageIterator is empty or was reset");
    }
    return state.pageIterator.getCurrentPage;
  },
  backupFormSession({ commit }) {
    commit("backupFormSession");
  },
  rehydrateBackupFormSession({ commit }, formId: FormId) {
    commit("rehydrateBackupFormSession", formId);
  },
  resetFormState({ commit }) {
    commit("resetFormState");
  },
};
