import { registerDecorator, ValidationOptions, ValidationArguments } from "class-validator";


// class-validator @Min & @Max don't work with strings
export function StringMax(maxValue: number, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: "stringMax",
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [maxValue],
      validator: {
        validate(stringValue: string, args: ValidationArguments) {
          const [maxValue] = args.constraints;
          const numericValue = parseFloat(stringValue);
          return !isNaN(numericValue) && numericValue <= maxValue;
        },
        defaultMessage(args: ValidationArguments) {
          return `${args.property} must be a number less than or equal to ${args.constraints[0]}`;
        },
      },
    });
  };
}

export function StringMin(minValue: number, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: "stringMin",
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [minValue],
      validator: {
        validate(stringValue: string, args: ValidationArguments) {
          const [minValue] = args.constraints;
          const numericValue = parseFloat(stringValue);
          return !isNaN(numericValue) && numericValue >= minValue;
        },
        defaultMessage(args: ValidationArguments) {
          return `${args.property} must be a number greater than or equal to ${args.constraints[0]}`;
        },
      },
    });
  };
}
