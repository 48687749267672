import { Appointment, AppointmentSchedule, PhoneCountryCode } from "@ambii-com/core";
import { Sex } from "@ambii-com/core";
import dayjs from "dayjs";

const EMPTY_TEXT = "";

export class AppointmentForm {
  schedule: AppointmentSchedule;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  tel: string;
  dob: string;
  cardNumber: string;
  sex: Sex;
  freeInput: string;
  email?: string;
  appointmentId: string;
  index?: number;
  telCountryCode?: PhoneCountryCode;
  constructor(
    schedule: AppointmentSchedule,
    firstName: string,
    lastName: string,
    firstNameKana: string,
    lastNameKana: string,
    tel: string,
    dob: string,
    cardNumber: string,
    sex: Sex,
    freeInput: string,
    email?: string,
    appointmentId: string = "",
    index?: number,
    telCountryCode?: PhoneCountryCode
  ) {
    this.schedule = schedule;
    this.firstName = firstName;
    this.lastName = lastName;
    this.firstNameKana = firstNameKana;
    this.lastNameKana = lastNameKana;
    this.tel = tel;
    this.dob = dob;
    this.cardNumber = cardNumber;
    this.sex = sex;
    this.freeInput = freeInput;
    this.email = email;
    this.appointmentId = appointmentId;
    this.index = index;
    this.telCountryCode = telCountryCode;
  }
  static createFromAppointmentDoc(
    appointment: Appointment,
    schedule: AppointmentSchedule
  ): AppointmentForm {
    return new AppointmentForm(
      schedule,
      appointment.firstName,
      appointment.lastName,
      appointment.firstNameKana,
      appointment.lastNameKana,
      appointment.tel,
      appointment.dob,
      appointment.cardNumber,
      appointment.sex,
      "",
      appointment.contactEmail,
      appointment.id,
      appointment.index,
      appointment.telCountryCode
    );
  }
  resetGuestFields(): AppointmentForm {
    return new AppointmentForm(
      this.schedule,
      this.firstName,
      this.lastName,
      this.firstNameKana,
      this.lastNameKana,
      this.tel,
      this.dob,
      this.cardNumber,
      this.sex,
      this.freeInput,
      undefined,
      this.appointmentId
    );
  }
}

export class DateContent {
  year: string;
  month: string;
  date: string;
  constructor(year: string, month: string, date: string) {
    this.year = year;
    this.month = month;
    this.date = date;
  }
  get toDisplayText(): string {
    return !this.isBlank
      ? dayjs(`${this.year}-${this.month}-${this.date}`).format("YYYY-MM-DD")
      : EMPTY_TEXT;
  }
  get isBlank(): boolean {
    return !this.year || !this.month || !this.date;
  }
}
