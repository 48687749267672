import { AppointmentForm } from "~/classes/AppointmentForm";
import { GetterTree, ActionTree, MutationTree } from "vuex";

export const state = () => ({
  menuId: null as string,
  isSaveProfile: false as boolean,
  appointmentPatientCount: null as number,
  appointmentForms: [] as AppointmentForm[],
  appointmentTimeSlots: [] as Map<string, string>[],
  selectedRelatedPatients: [] as Map<string, RelatedPatient>[],
});

type AppointmentFormState = ReturnType<typeof state>;
interface RelatedPatient {
  id: string; lastName: string;
  firstName: string;
  // add other relevant fields
}

export const getters: GetterTree<AppointmentFormState, any> = {};

export const mutations: MutationTree<AppointmentFormState> = {
  SET_MENUID(state, menuId: MenuId) {
    state.menuId = menuId
  },
  SET_IS_SAVE_PROFILE(state, isSaveProfile: boolean) {
    state.isSaveProfile = isSaveProfile
  },
  SET_APPOINTMENT_PATIENT_COUNT(state, appointmentPatientCount: number) {
    if (appointmentPatientCount < 1) {
      throw new Error('Appointment patient count must be positive');
    }
    state.appointmentPatientCount = appointmentPatientCount;
  },
  SET_APPOINTMENT_FORMS(state, appointmentForms: AppointmentForm[]) {
    state.appointmentForms = appointmentForms;
  },
  SET_APPOINTMENT_TIME_SLOTS(state, appointmentTimeSlots: Array<Map<string, string>>) {
    state.appointmentTimeSlots = appointmentTimeSlots;
  },
  SET_SELECTED_RELATED_PATIENTS(state, selectedRelatedPatients: Map<string, RelatedPatient>[]) {
    state.selectedRelatedPatients = selectedRelatedPatients;
  },
  RESET_GUEST_FIELDS(state) {
    if(state.appointmentForms.length > 0) {
      state.appointmentForms[0] = state.appointmentForms[0].resetGuestFields();
    }
  },
  RESET_APPOINTMENT_FORMS(state) {
    state.appointmentForms = [];
  },
  RESET_MENUID(state) {
    state.menuId = null
  },
  RESET_APPOINTMENT_TIME_SLOTS(state) {
    state.appointmentTimeSlots = [];
  },
  REMOVE_LAST_TIME_SLOT(state) {
    state.appointmentTimeSlots.pop();
  }
};

export const actions: ActionTree<AppointmentFormState, any> = {
  setMenuId({ commit },  menuId: MenuId) {
    commit("SET_MENUID", menuId);
  },
  setIsSaveProfile({ commit },  isSaveProfile: boolean) {
    commit("SET_IS_SAVE_PROFILE", isSaveProfile);
  },
  setAppointmentPatientCount({ commit }, appointmentPatientCount: number) {
    commit("SET_APPOINTMENT_PATIENT_COUNT", appointmentPatientCount);
  },
  setAppointmentForms({ commit }, appointmentForms: AppointmentForm[]) {
    try {
      if (!Array.isArray(appointmentForms)) {
        throw new Error('appointmentForms must be an array');
      }
      if (appointmentForms.some(form => !(form instanceof AppointmentForm))) {
        throw new Error('All elements must be instances of AppointmentForm');
      }
      commit("SET_APPOINTMENT_FORMS", appointmentForms);
    } catch (error) {
      console.error('Failed to set appointment forms:', error);
      throw error;
    }
  },
  setAppointmentTimeSlots({ commit }, appointmentTimeSlots: Array<Map<string, string>>) {
    commit("SET_APPOINTMENT_TIME_SLOTS", appointmentTimeSlots);
  },
  setSelectedRelatedPatients({ commit }, selectedRelatedPatients: Map<string, RelatedPatient>[]) {
    commit("SET_SELECTED_RELATED_PATIENTS", selectedRelatedPatients);
  },
  resetMenuId({ commit }) {
    commit("RESET_MENUID");
  },
  resetGuestFields({ commit }) {
    commit("RESET_GUEST_FIELDS");
  },
  resetAppointmentForms({ commit }) {
    commit("RESET_APPOINTMENT_FORMS");
  },
  resetAppointmentTimeSlots({ commit }) {
    commit("RESET_APPOINTMENT_TIME_SLOTS");
  },
  removeLastTimeSlot({ commit }) {
    commit("REMOVE_LAST_TIME_SLOT");
  }
};


